import { makeStyles, Theme } from '@material-ui/core/styles';

export function useMySchedulingStyles() {
    const useStyles = makeStyles((theme: Theme) => ({
        main: {
            width: '100vw',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0px 10px',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        tabsInfo: {
            justifyContent: 'center',
            minWidth: 960,
            height: 47,
            borderRadius: 4,
            '&:hover': {
                background: theme.palette.primary.main,
            },
        },
        boxIndicatorsBack: {
            width: '100%',
            justifyContent: 'center',
            margin: '20px 0px',
        },
        indicatorsBack: {
            display: 'flex',
            width: '100%',
            color: '#373F41',
            maxWidth: '1250px',
            alignItems: 'center',
        },
        tableInformation: {
            background: theme.palette.primary.main,
            color: theme.palette.text.primary,
        },
        flexContainer: {
            alignItems: 'center',
            height: '56px',
            background: theme.palette.text.primary,
            color: theme.palette.primary.main,
        },
        selected: {
            background: theme.palette.primary.main,
            color: theme.palette.text.primary,
        },
        tabContainer: {
            minWidth: 300,
            height: 56,
            marginTop: 0,
            padding: 5,
            borderRadius: 4,
            [theme.breakpoints.down('md')]: {
                minWidth: 56,
            },

            '& .MuiTab-wrapper': {
                flexDirection: 'row',
            },
        },
        labelIcon: {
            paddingTop: 0,
            minHeight: 0,
        },
        indicator: {
            width: 0,
            height: 0,
        },
        textLabeltab: {
            fontSize: 16,
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontStyle: 'normal',
            lineHeight: '20px',
            textTransform: 'uppercase',
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
        },
        tabIcon: {
            marginRight: 11,
            [theme.breakpoints.down('md')]: {
                marginRight: 0,
            },
        },
    }));
    const classes = useStyles();

    return { classes };
}