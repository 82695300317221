import { useLocation } from "react-router-dom";
import { clearObject } from "../utils/clearObject";

export function useQueryParams(onlyTruthy = false) {
    const searchParams = new URLSearchParams(useLocation().search);

    if (onlyTruthy) {
        return clearObject(Object.fromEntries(searchParams));
    }

    return Object.fromEntries(searchParams);
}