import { createContext, useEffect, useState, ReactNode } from "react";
import getCookie from "../utils/getCookies";
import { UserData } from "../interfaces/user";

type AuthContextType = {
    authUser: UserData | null;
}

type AuthProviderProps = {
    children: ReactNode;
}

const AuthContext = createContext<AuthContextType>({ authUser: null });

const AuthProvider = ({ children }: AuthProviderProps) => {
    const userDataStorage: string | null = getCookie('gov_user_data');
    const [authUser, setAuthUser] = useState<UserData | null>(null);

    useEffect(() => {
        if (userDataStorage) {
            const parsedUserData = JSON.parse(userDataStorage);
            setAuthUser(parsedUserData);
        }
    }, [userDataStorage]);

    return (
        <AuthContext.Provider value={{ authUser }}>
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider };