import isValid from 'date-fns/isValid';
import { cpfMask } from '../../../../../../utils/cpfFormat';
import { useScheduleOnline } from '../../../../../../contexts/SchedulerOnlineContext';

export const useDependent = () => {
  const optionsDependentes = [
    {
      id: 0,
      option: 'Filho',
    },
    {
      id: 1,
      option: 'Filha',
    },
    {
      id: 2,
      option: 'Enteado',
    },
    {
      id: 3,
      option: 'Enteada',
    },
    {
      id: 4,
      option: 'Pai',
    },
    {
      id: 5,
      option: 'Mãe',
    },
    {
      id: 6,
      option: 'Avó',
    },
    {
      id: 7,
      option: 'Avô',
    },
    {
      id: 8,
      option: 'Tutor',
    },
    {
      id: 9,
      option: 'Tutora',
    },
  ];

  const {
    setCpfDependente,
    setErrorCpfDependente,
    setNameDependente,
    setErrorNameDependente,
    setBirthDateDependente,
    setGrauDependenteSelected,
  } = useScheduleOnline();

  const handleChangeCPF = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCpfDependente(cpfMask(event.target.value));
    setErrorCpfDependente(undefined);
  };

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNameDependente(event.target.value);
    setErrorNameDependente(undefined);
  };

  const handleChangeDate = (date: Date) => {
    if (date && isValid(date)) {
      setBirthDateDependente(date);
    }
  };

  const handleChangeGrauDependente = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setGrauDependenteSelected(event.target.value as string);
  };

  return {
    handleChangeCPF,
    handleChangeName,
    handleChangeDate,
    handleChangeGrauDependente,
    optionsDependentes,
  };
};
