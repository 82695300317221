// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import createTheme from '@material-ui/core/styles/createTheme';
import getCookie from '../utils/getCookies';

const colorTheme = (): string => {
  if (getCookie('perfil')) {
    switch (getCookie('perfil')) {
      case 'Cidadão':
        return '#004F9F';

      case 'Servidor':
        return '#004F9F';

      case 'Empresa':
        return '#004F9F';

      default:
        return '#004F9F';
    }
  }
  return '#004F9F';
};

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: colorTheme(),
      dark: colorTheme(),
    },
    secondary: {
      main: '#055052',
    },
    text: {
      primary: '#fff',
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: '#004F9F', // Cor do texto (mês e ano)
      },
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          color: '#004F9F',
        },
        '&$completed': {
          color: '#004F9F',
        },
      },
    },
    MuiStepLabel: {
      label: {
        fontSize: '0.75rem',
        '&$active': {
          color: '#004F9F',
        },
        '&$completed': {
          color: '#004F9F',
        },
      },
    },
    MuiButton: {
      outlined: {
        borderRadius: 0,
        padding: '8px 8px',
        textTransform: 'none',
      },
    },
  },
  typography: {
    body1: {
      fontFamily: 'Roboto',
      textTransform: 'none',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      textAlign: 'center',
      textShadow: '0px 6px 14px rgba(24, 39, 75, 0.12)',
    },
    h3: {
      fontFamily: 'Roboto',
    },
    h4: {
      fontFamily: 'Roboto',
      fontWeight: 'bold',
      color: '#373F41',
    },
    h5: {
      fontFamily: 'Roboto',
    },
    h6: {
      fontFamily: 'Roboto',
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    subtitle1: {
      color: '#d3d3d3',
    },
  },
});

export default theme;
