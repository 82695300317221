import makeStyles from '@material-ui/styles/makeStyles';
import theme from '../../../../../../services/theme-service';

export const useStyles = makeStyles({
  box: {
    borderRadius: 4,
    border: `1px solid rgba(225, 230, 239, 1)`,
    padding: '24px',
    margin: '24px 32px 0px 32px',
  },
  titleBox: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    textTransform: 'uppercase',
    margin: '2px 0px 30px 0px',
    color: '#333',
  },
  formControlList: {
    width: '100%',
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  textarea: {
    borderRadius: '5px',
    margin: '24px 0px',
    fontSize: '16px',
    color: theme.palette.primary.main,
  },
  text: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    textAlign: 'center',
    lineHeight: 1.5,
    whiteSpace: 'pre-line',
  },
  cardUploads: {
    width: '100%',
    border: `1px solid #E1E6EF`,
    boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
    backgroundColor: 'rgba(136, 136, 136, 0.1)',
    color: '#333',
    display: 'flex',
    borderRadius: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    marginBottom: 2,
    marginTop: 5,
  },
  iconUpload: {
    color: '#9E9E9E',
  },
  rootUpload: {
    border: '2px dashed #ccc',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: '#f9f9f9',
    minHeight: 140,
    '&:hover': {
      backgroundColor: '#f0f0f0',
    },
  },
  infoText: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    margin: '8px 0px',
  },
  errorUploadCard: {
    width: '100%',
    border: `1px solid #F03D3D`,
    boxShadow: '0px 8px 16px rgba(11, 31, 77, 0.04)',
    display: 'flex',
    borderRadius: 4,
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 10px',
    marginBottom: 2,
    marginTop: 5,
  },
  errorTextStyle: {
    color: '#ff4d4d',
    marginTop: '5px',
    fontSize: 12,
    fontWeight: 400,
  },
});
