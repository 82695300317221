import makeStyles from '@material-ui/styles/makeStyles';
import theme from '../../../../../../services/theme-service';

export const useStyles = makeStyles({
  root: {
    color: theme.palette.primary.main,
  },
  box: {
    borderRadius: 4,
    border: `1px solid rgba(225, 230, 239, 1)`,
    padding: '24px',
    margin: '24px 32px 0px 32px',
  },
  titleBox: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 500,
    textTransform: 'uppercase',
    margin: '2px 0px 30px 0px',
    color: '#333',
  },
  formControl: {
    marginBottom: 20,
    margin: theme.spacing(1),
    width: '100%',
  },
  textLabel: {},
  colorInputText: {
    color: theme.palette.primary.main,
  },
  menuItem: {
    color: theme.palette.primary.main,
  },
  adornedEnd: {
    paddingRight: 0,
  },
});
