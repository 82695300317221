import {
  Badge,
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { addDays, isAfter } from 'date-fns';
import { useEffect, useState } from 'react';
import {
  MdSchedule,
  MdStar,
  MdSupervisorAccount,
  MdToday,
} from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { PaginationService } from '../../Components';
import {
  IAttendenceResponse,
  IMyAttendences,
  IRespost,
} from '../../interfaces/atendimento';
import { IParamsHistory } from '../../services/agendamento';
import { meusAtendimentos } from '../../services/atendimentos';
import theme from '../../services/theme-service';
import { extractDateFormat } from '../../utils/dateFormat';
import getCookie from '../../utils/getCookies';
import { FilterAttendence, HistoryDialog } from './components';
import CancelDialog from './components/CancelDialog';
import { useWebsocket } from '../../hooks/useWebsocket';
import { useAttendenceRequestStyles } from './styles';
import useAuth from '../../hooks/useAuth';

type WsMessageData = {
  room_id: string;
  payload: {
    agendamento_id: string;
    user: string;
    status: string;
  }
};

export default function AttendenceRequest(): JSX.Element {
  const history = useHistory();
  const { authUser } = useAuth();
  const { classes, isSmallScreen } = useAttendenceRequestStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const [filterParams, setFilterParams] = useState<IParamsHistory | null>(null);
  const [myAttendencesData, setMyAttendencesData] = useState<IAttendenceResponse>();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [attendenceSelected, setAttendenceSelected] = useState<IMyAttendences>();

  useWebsocket({
    queryParams: {
      hostname: authUser?.cpf || Math.random().toString(36).substring(7),
      room_name: "update-workspace-schedule",
    },
    onMessage: (event: MessageEvent<string>) => {
      if (!myAttendencesData) return;

      const data: WsMessageData = JSON.parse(event.data);

      const updatedData = myAttendencesData.results.map((item) => {
        if (item.id === data.payload.agendamento_id) {
          return { ...item, status: data.payload.status };
        }

        return item;
      });

      setMyAttendencesData({
        ...myAttendencesData,
        results: updatedData,
      });
    }
  });

  const handleCancelDialog = (attendence: IMyAttendences) => {
    setOpenCancelDialog(true);
    setAttendenceSelected(attendence);
  };
  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setAttendenceSelected(undefined);
  };

  const handleHistoryDialog = (attendence: IMyAttendences) => {
    setOpenHistoryDialog(true);
    setAttendenceSelected(attendence);
  };

  const handleCloseCancelDialogHistory = () => {
    setOpenHistoryDialog(false);
    setAttendenceSelected(undefined);
  };

  const handleToAttendence = (id: string) => {
    history.push(`/workspace/atendimento_online/${id}`);
  };

  const getMyAttendences = async (
    page: number,
  ): Promise<void> => {
    (window as any).scrollTo(0, 0);
    setMyAttendencesData(undefined);
    const token: string | null = getCookie('gov_access_token_sso');
    setLoading(true);
    if (token) {
      try {
        const { data } = await meusAtendimentos(token, page, filterParams);
        setMyAttendencesData(data);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getMyAttendences(1);
  }, [filterParams]);

  const countNotReadResp = (respostas: IRespost[], user_name: string) => {
    let count = 0;
    respostas.forEach(({ lido }) => {
      if (!lido) {
        count += 1;
      }
    });
    return count;
  };

  const handleToEvaluetion = (ticket: string) => {
    history.push(`/workspace/avaliar_atendimento_online/${ticket}`);
  };

  const handleEvaluationDate = (schedule: IMyAttendences) => {
    if (!schedule?.avaliacao_online) {
      return;
    }
    const date = new Date(schedule.avaliacao_online.created_at);
    const now = new Date();
    const afterOneWeek = addDays(date, 7);
    if (isAfter(afterOneWeek, now) && schedule.avaliacao_online.pendente) {
      return (
        <Button
          className={classes.textButtonGuiche}
          color='primary'
          variant='outlined'
          onClick={() => handleToEvaluetion(schedule.avaliacao_online.id)}
          style={{ marginBottom: 10 }}
        >
          <Typography className={classes.actionsButtonText}>Avaliar</Typography>
        </Button>
      );
    }
    return;
  };

  return (
    <>
      <Grid container>
        <FilterAttendence setFilter={setFilterParams} />
      </Grid>

      {loading ? (
        <Box
          marginBottom='60px'
          width='100%'
          display='flex'
          justifyContent='center'
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {myAttendencesData && myAttendencesData.results.length > 0 ? (
            myAttendencesData?.results.map(attendence => (
              <Container key={`attendence-${attendence.id}`}>
                <Grid
                  container
                  alignItems='center'
                  justify='space-between'
                  className={classes.boxTermsList}

                >
                  <Box className={classes.boxInformationTitle}>
                    <Typography className={classes.title}>
                      {attendence.unidade.nome}
                      {attendence?.guiche && (
                        <>(Guichê {attendence.guiche.ordem})</>
                      )}
                    </Typography>
                    {attendence.status === 'Cancelado' && (
                      <Typography
                        className={classes.status}
                        style={{
                          color: '#F44336',
                          border: '1px solid rgba(248, 50, 69, 0.08)',
                          background: 'rgba(248, 50, 69, 0.08)',
                        }}
                      >
                        Cancelado
                      </Typography>
                    )}
                    {attendence.status === 'Finalizado' && (
                      <Typography
                        className={classes.status}
                        style={{
                          color: '#43A047',
                          border: '1px solid rgba(67, 160, 71, 0.08)',
                          background: 'rgba(67, 160, 71, 0.08)',
                        }}
                      >
                        Finalizado
                      </Typography>
                    )}
                    {attendence.status === 'Aguardando' && (
                      <Typography
                        className={classes.status}
                        style={{
                          color: '#0F6FB7',
                          border: '1px solid rgba(15, 111, 183, 0.08)',
                          background: 'rgba(15, 111, 183, 0.08)',
                        }}
                      >
                        Aguardando
                      </Typography>
                    )}
                    {attendence.status === 'Em Atendimento' && (
                      <Typography
                        className={classes.status}
                        style={{
                          color: '#0F6FB7',
                          border: '1px solid rgba(15, 111, 183, 0.08)',
                          background: 'rgba(15, 111, 183, 0.08)',
                        }}
                      >
                        Em atendimento
                      </Typography>
                    )}
                    {attendence.status === 'Não Compareceu' && (
                      <Typography
                        className={classes.status}
                        style={{
                          color: '#851d15',
                          border: '1px solid rgba(141, 18, 30, 0.08)',
                          background: 'rgba(141, 18, 30, 0.08)',
                        }}
                      >
                        Não Compareceu
                      </Typography>
                    )}
                  </Box>
                  <Grid item sm={10} xs={12}>
                    <Typography className={classes.subTitle}>
                      {attendence.servico.titulo}
                    </Typography>
                    <Typography className={classes.description}>{`Órgão: ${attendence.orgao_responsavel || 'Sem descrição'}`}</Typography>
                    {attendence?.protocolo && (
                      <Typography className={classes.description}>
                        {`Protocolo de Agendamento: ${attendence.protocolo}`}
                      </Typography>
                    )}
                    {attendence?.protocolo_atendimento && (
                      <Typography className={classes.description}>
                        {`Protocolo de Atendimento: ${attendence.protocolo_atendimento}`}
                      </Typography>
                    )}
                    {attendence?.anexos?.length > 0 && (
                      <Box
                        display='flex'
                        gridGap='8px'
                        alignItems='center'
                        justifyContent={
                          isSmallScreen ? 'center' : 'flex-start'
                        }
                        flexWrap='wrap'
                      >
                        <Typography className={classes.description}>
                          Anexos:
                        </Typography>
                        {attendence.anexos.map((anexo, index) => (
                          <Typography
                            key={`anexo-${anexo.id}`}
                            className={classes.description}
                            onClick={() =>
                              (window as any).open(anexo.arquivo)
                            }
                            style={{
                              cursor: 'pointer',
                              color: theme.palette.primary.main,
                              fontWeight: 600,
                            }}
                          >
                            Anexo {index + 1}
                            {index === attendence.anexos.length - 1
                              ? ''
                              : ','}
                          </Typography>
                        ))}
                      </Box>
                    )}
                    <Box display='flex' className={classes.boxInfo}>
                      <Typography className={classes.textInfo}>
                        {attendence.dependentes ? (
                          <>
                            <MdSupervisorAccount className={classes.icon} />
                            <Typography className={classes.textInfo}>
                              Dependentes
                            </Typography>
                          </>
                        ) : (
                          <>
                            <MdSupervisorAccount className={classes.icon} />
                            <Typography className={classes.textInfo}>
                              Sem dependentes
                            </Typography>
                          </>
                        )}
                      </Typography>
                      <Typography className={classes.textInfo}>
                        <MdToday className={classes.icon} />
                        {extractDateFormat(attendence.data)}
                      </Typography>
                      <Typography className={classes.textInfo}>
                        <MdSchedule className={classes.icon} />
                        {attendence.hora}
                      </Typography>
                      {attendence?.avaliacao_online?.pendente === false && (
                        <Typography className={classes.textInfo}>
                          <MdStar className={classes.iconStar} />
                          {`${attendence.avaliacao_online?.avaliacao_consumo} estrelas`}
                        </Typography>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <Box
                      marginBottom='10px'
                      display='flex'
                      alignItems='center'
                      flexDirection='column'
                    ></Box>
                    <Box
                      display='flex'
                      alignItems='center'
                      flexDirection='column'
                      gridGap={8}
                    >
                      {handleEvaluationDate(attendence)}
                      {attendence.status === 'Aguardando' ? (
                        <Button
                          variant='outlined'
                          color='inherit'
                          className={classes.buttonCancel}
                          onClick={() => handleCancelDialog(attendence)}
                        >
                          <Typography className={classes.actionsButtonText}>
                            Cancelar
                          </Typography>
                        </Button>
                      ) : (
                        <>
                          {countNotReadResp(
                            attendence.respostas,
                            attendence.user.username,
                          ) ? (
                            <Badge
                              className={classes.badgeButton}
                              badgeContent={countNotReadResp(
                                attendence.respostas,
                                attendence.user.username,
                              )}
                              color='error'
                            >
                              <Button
                                className={classes.textButtonGuiche}
                                variant='outlined'
                                color='primary'
                                onClick={() =>
                                  handleToAttendence(attendence.id)
                                }
                              >
                                Mensagem
                              </Button>
                            </Badge>
                          ) : (
                            <>
                              {attendence.status !== 'Cancelado' ? (
                                <Button
                                  className={classes.textButtonGuiche}
                                  variant='outlined'
                                  color='primary'
                                  onClick={() =>
                                    handleToAttendence(attendence.id)
                                  }
                                >
                                  Ver conversa
                                </Button>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </>
                      )}
                      {attendence.status === 'Finalizado' && (
                        <Button
                          className={classes.textButtonGuiche}
                          color='primary'
                          variant='outlined'
                          onClick={() => handleHistoryDialog(attendence)}
                        >
                          <Typography className={classes.actionsButtonText}>
                            Ver histórico
                          </Typography>
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Container>
            ))
          ) : (
            <Box
              marginBottom='60px'
              minHeight='100px'
              display='flex'
              alignItems='center'
              justifyContent='center'
              width='100%'
            >
              <Typography style={{ textAlign: 'center' }} variant='h4'>
                Nenhum resultado foi encontrado.
              </Typography>
            </Box>
          )}
          {attendenceSelected && (
            <CancelDialog
              open={openCancelDialog}
              attendence={attendenceSelected}
              handleClose={handleCloseCancelDialog}
            />
          )}
        </>
      )}
      {attendenceSelected && (
        <HistoryDialog
          open={openHistoryDialog}
          schedule={attendenceSelected}
          handleClose={handleCloseCancelDialogHistory}
        />
      )}

      {myAttendencesData && myAttendencesData.results.length ? (
        <PaginationService
          getSearch={getMyAttendences}
          listSearch={myAttendencesData}
        />
      ) : (
        ''
      )}
    </>
  );
}
