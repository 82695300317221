import { useScheduleOnline } from '../../../../../../contexts/SchedulerOnlineContext';
import { UserData } from '../../../../../../interfaces/user';
import {
  anexarArquivo,
  atenderOnline,
  incluirDependente,
  salvarMotivosTopicos,
} from '../../../../../../services/atendimentos';
import { SavedScheduleProps } from '../../../../../../types/scheduling';
import { removeCpfMask, validateCpf } from '../../../../../../utils/cpfFormat';
import getCookie from '../../../../../../utils/getCookies';

export const useActions = () => {
  const {
    formDataReason,
    serviceData,
    files,
    setIsAttachmentRequired,
    cpfDependente,
    nameDependente,
    setErrorNameDependente,
    setErrorCpfDependente,
    birthDateDependente,
    grauDependenteSelected,
    unitySelected,
    selectedDate,
    scheduleSelected,
    setScheduleServiceUser,
    setScheduleService,
    setLoading,
    typeSchedule,
    setSavedSchedule,
  } = useScheduleOnline();

  const handleSubmitMotivos = async (savedSchedule: any) => {
    const token: string | null = getCookie('gov_access_token_sso');
    try {
      await salvarMotivosTopicos(
        token,
        savedSchedule.id.toString(),
        formDataReason.descriptionProblem,
        formDataReason.reasonService,
      );
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const handleUploads = async (savedSchedule: any) => {
    if (serviceData?.configuracao_agendamento?.anexo && files?.length === 0) {
      setIsAttachmentRequired(true);
      return;
    }

    const token: string | null = getCookie('gov_access_token_sso');

    if (savedSchedule && files.length >= 1) {
      try {
        // eslint-disable-next-line no-restricted-syntax
        for (const fileUpload of files) {
          anexarArquivo(
            token,
            fileUpload.file.name,
            fileUpload.file.type,
            fileUpload.file,
            savedSchedule.id.toString(),
          );
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleDependente = async (savedSchedule: any) => {
    const token: string | null = getCookie('gov_access_token_sso');
    const dataStorage: string | null = getCookie('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    const cpfDependenteWithoutMask = removeCpfMask(cpfDependente);

    if (nameDependente && nameDependente.length < 6) {
      setErrorNameDependente('Nome deve ter no mínimo 6 caracteres.');
      return;
    }

    if (cpfDependenteWithoutMask) {
      if (
        !validateCpf(cpfDependenteWithoutMask) ||
        cpfDependenteWithoutMask.length < 11
      ) {
        setErrorCpfDependente('CPF inválido.');
      }
    }

    if (savedSchedule.para_dependente) {
      try {
        await incluirDependente(
          savedSchedule.id,
          cpfDependenteWithoutMask,
          nameDependente,
          birthDateDependente,
          userData.cpf,
          grauDependenteSelected,
          token,
        );
      } catch (error) {
        console.log(error);
      }
    }
  };

  const schedulerService = async (): Promise<SavedScheduleProps> => {
    const token: string | null = getCookie('gov_access_token_sso');
    const dataStorage: string | null = getCookie('gov_user_data');
    const userData: UserData | null = dataStorage
      ? JSON.parse(dataStorage)
      : null;
    if (token && userData) {
      try {
        const { data } = await atenderOnline(
          token,
          serviceData.id,
          unitySelected,
          serviceData.titulo,
          `Agendamento para ${serviceData.titulo}`,
          serviceData.setor_id,
          'Video',
          selectedDate,
          scheduleSelected,
          typeSchedule === 'me' ? false : true,
        );
        setScheduleServiceUser(data);

        return data;
      } catch (err) {
        if (err.response.data.message) {
          setScheduleServiceUser(err.response.data.message);
        } else if (err.response?.data?.[0]) {
          setScheduleService(err.response?.data?.[0]);
        } else {
          setScheduleService('Algo inesperado ocorreu.');
        }
      }
    }
  };

  const executeFlow = async () => {
    try {
      setLoading(true);
      const saveResult = await schedulerService();

      if (saveResult) {
        try {
          await handleDependente(saveResult);
        } catch (error) {
          console.log('Erro no handleDependente, continuando:', error);
        }

        try {
          await handleUploads(saveResult);
        } catch (error) {
          console.log('Erro no handleUploads, continuando:', error);
        }

        try {
          await handleSubmitMotivos(saveResult);
        } catch (error) {
          console.log('Erro no handleSubmitMotivos, continuando:', error);
        }

        setSavedSchedule(saveResult);
      }
    } catch (error) {
      console.error('Erro crítico no fluxo:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    executeFlow,
  };
};
