import { useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import theme from '../../services/theme-service';

export function useAttendenceRequestStyles() {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const useStyles = makeStyles((theme: Theme) => ({
        boxIndicatorsBack: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            margin: '20px 0px',
        },
        indicatorsBack: {
            display: 'flex',
            width: '100%',
            color: '#373F41',
            maxWidth: '1250px',
            alignItems: 'center',
        },
        boxTermsList: {
            backgroundColor: '#fff',
            padding: 30,
            borderRadius: 4,
            margin: '20px 0px',
        },
        boxInformationTitle: {
            display: 'flex',
            width: '100%',
            maxWidth: '1105px',
            justifyContent: 'space-between',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'column-reverse',
            },
        },
        title: {
            fontSize: 16,
            fontWeight: 500,
            fontFamily: 'Roboto',
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        subTitle: {
            color: '#373F41',
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 500,
            margin: '16px 0px 12px 0px',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        description: {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: 16,
            color: '#373F41',
            textTransform: 'capitalize',
            [theme.breakpoints.down('sm')]: {
                textAlign: 'center',
            },
        },
        status: {
            fontSize: 14,
            fontFamily: 'Roboto',
            fontWeight: 500,
            textTransform: 'capitalize',
            padding: '5px 16px',
            borderRadius: 25,
            [theme.breakpoints.down('sm')]: {
                width: 'fit-content',
                marginBottom: 24,
                alignSelf: 'center',
            },
        },
        boxInfo: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: '#373F41',
            margin: '46px 0px 17px',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'Center',
            },
        },
        textInfo: {
            display: 'flex',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: 16,
            fontStyle: 'normal',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 26,
        },
        icon: {
            color: theme.palette.primary.main,
            marginRight: 7,
            width: 20,
            height: 20,
        },
        iconStar: {
            color: theme.palette.primary.main,
            marginRight: 5,
            width: 20,
            height: 20,
        },
        infoNumberGuiche: {
            fontFamily: 'Roboto',
            fontWeight: 700,
            fontSize: 28,
            color: theme.palette.primary.main,
        },
        infoTextGuiche: {
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: 16,
            textTransform: 'uppercase',
        },
        textButtonGuiche: {
            textTransform: 'uppercase',
            fontFamily: 'Roboto',
            width: 127,
            height: 45,
            padding: '10px 24px',
            borderRadius: 4,
            fontSize: '1rem',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        actionsButtonText: {
            textTransform: 'uppercase',
            fontFamily: 'Roboto',
        },
        buttonCancel: {
            color: '#E53535',
            borderColor: 'rgba(229,53,53, 0.7)',
            borderRadius: 4,
            padding: '12px 24px',
            width: 127,
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
            '&:hover': {
                backgroundColor: 'rgba(229,53,53, 0.1)',
                borderColor: '#E53535',
            },
        },
        notificationCounter: {
            position: 'absolute',
            top: -10,
            right: -10,
            padding: '3px 9px',
            borderRadius: '50%',
            backgroundColor: '#F5365C',
            color: theme.palette.text.primary,
            fontSize: 13,
        },
        badgeButton: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    }));
    const classes = useStyles();

    return { classes, isSmallScreen };
}