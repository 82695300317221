import {
  Box,
  Typography,
  Grid,
  Container,
  IconButton,
  Paper,
  Tabs,
  Tab,
  Icon,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { MdArrowBack } from "react-icons/md";
import { Breadcrumb } from '../../Components';
import { AttendenceRequest, SchedulingRequest, SchedulingInternal } from '..';
import OrganicSchedulingRequest from '../OrganicSchedulingRequest';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useMySchedulingStyles } from './styles';

export default function MyScheduling(): JSX.Element {
  const history = useHistory();
  const { classes } = useMySchedulingStyles();
  const { selectedTab } = useQueryParams(true);

  const linksBreadcrumb = [
    {
      name: 'Meus agendamentos',
      redirectTo: '#',
    },
  ];
  return (
    <>
      <Breadcrumb links={linksBreadcrumb} />
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.boxIndicatorsBack}>
              <Box className={classes.indicatorsBack}>
                <IconButton
                  onClick={() => history.push("/workspace/")}
                >
                  <MdArrowBack />
                </IconButton>
                <Typography style={{ fontFamily: "Roboto", fontWeight: 700 }}>
                  Meus agendamentos
                </Typography>
              </Box>
            </Box>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Paper variant="outlined" elevation={0}>
                <Box>
                  <Tabs
                    value={selectedTab || 'presencial'}
                    indicatorColor="primary"
                    centered={true}
                    textColor="inherit"
                    scrollButtons="off"
                    onChange={(_, value) => {
                      history.replace(`?selectedTab=${value}`);
                    }}
                    classes={{
                      flexContainer: classes.flexContainer,
                    }}
                    TabIndicatorProps={{ className: classes.indicator }}
                  >
                    <Tab
                      value="presencial"
                      classes={{
                        selected: classes.selected,
                        labelIcon: classes.labelIcon,
                      }}
                      className={classes.tabContainer}
                      icon={
                        <Icon
                          className={`${classes.tabIcon} material-icons-round`}
                        >
                          event
                        </Icon>
                      }
                      label={
                        <Typography className={classes.textLabeltab}>
                          agendamentos presenciais
                        </Typography>
                      }
                    />

                    <Tab
                      value="online"
                      classes={{
                        selected: classes.selected,
                        labelIcon: classes.labelIcon,
                      }}
                      className={classes.tabContainer}
                      icon={
                        <Icon
                          className={`${classes.tabIcon} material-icons-round`}
                        >
                          support_agent
                        </Icon>
                      }
                      label={
                        <Typography className={classes.textLabeltab}>
                          agendamentos online
                        </Typography>
                      }
                    />
                    <Tab
                      value="complementar"
                      classes={{
                        selected: classes.selected,
                        labelIcon: classes.labelIcon,
                      }}
                      className={classes.tabContainer}
                      icon={
                        <Icon
                          className={`${classes.tabIcon} material-icons-round`}
                        >
                          move_to_inbox
                        </Icon>
                      }
                      label={
                        <Typography className={classes.textLabeltab}>
                          agendamentos complementares
                        </Typography>
                      }
                    />
                    <Tab
                      value="organico"
                      classes={{
                        selected: classes.selected,
                        labelIcon: classes.labelIcon,
                      }}
                      className={classes.tabContainer}
                      icon={
                        <Icon
                          className={`${classes.tabIcon} material-icons-round`}
                        >
                          date_range
                        </Icon>
                      }
                      label={
                        <Typography className={classes.textLabeltab}>
                          agendamentos Orgânicos
                        </Typography>
                      }
                    />
                  </Tabs>
                </Box>
              </Paper>
            </Grid>
          </Grid>
          {(!selectedTab || selectedTab === 'presencial') && <SchedulingRequest />}
          {selectedTab === 'online' && <AttendenceRequest />}
          {selectedTab === 'complementar' && <SchedulingInternal />}
          {selectedTab === 'organico' && <OrganicSchedulingRequest />}
        </Grid>
      </Container>
    </>
  );
}
