import React, { useEffect } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import ptBR from 'date-fns/locale/pt-BR';
import { MdEvent } from 'react-icons/md';
import { UnityData } from '../../../../../../types/scheduling';
import { useScheduleOnline } from '../../../../../../contexts/SchedulerOnlineContext';
import { useScheduler } from './useScheduler';
import { useStyles } from './styles';

export default function Scheduling() {
  const {
    handleChangeUnit,
    handleChangeDate,
    isDateAvailable,
    handleMonthChange,
    getStartAndEndOfMonth,
    handleCheckInterval,
  } = useScheduler();
  const {
    unitsList,
    scheduleServiceUser,
    scheduleService,
    unitySelected,
    selectedDate,
    availableDates,
    setScheduleSelected,
    serviceData,
  } = useScheduleOnline();

  const classes = useStyles();

  useEffect(() => {
    if (unitySelected) {
      const { data_inicio, data_fim } = getStartAndEndOfMonth(new Date());
      handleCheckInterval(data_inicio, data_fim, unitySelected, serviceData.id);
    }
  }, [unitySelected]);

  return (
    <Box className={classes.box}>
      <Typography className={classes.titleBox}>Agendamento</Typography>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FormControl
            classes={{ root: classes.root }}
            variant='outlined'
            className={classes.formControl}
            disabled
            required
            fullWidth
          >
            <InputLabel
              className={classes.textLabel}
              id='demo-simple-select-outlined-label'
            >
              Serviço
            </InputLabel>
            <Select
              label='Serviço'
              classes={{
                select: classes.menuItem,
              }}
              MenuProps={{
                MenuListProps: {
                  disablePadding: true,
                },
              }}
              value={serviceData.slug}
            >
              <MenuItem
                key={serviceData?.id}
                className={classes.menuItem}
                value={serviceData.slug}
              >
                {serviceData.titulo}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl
            classes={{ root: classes.root }}
            error={typeof scheduleServiceUser === 'string'}
            variant='outlined'
            className={classes.formControl}
            required
            fullWidth
          >
            <InputLabel
              className={classes.textLabel}
              id='demo-simple-select-outlined-label'
            >
              Selecione a unidade
            </InputLabel>
            <Select
              error={typeof scheduleServiceUser === 'string'}
              labelId='demo-simple-select-outlined-label'
              id='demo-simple-select-outlined'
              onChange={handleChangeUnit}
              label='Selecione a unidade'
              classes={{
                select: classes.menuItem,
              }}
              MenuProps={{
                MenuListProps: {
                  disablePadding: true,
                },
              }}
            >
              {unitsList?.map((unit: UnityData) => (
                <MenuItem
                  key={unit.setor_unidade}
                  className={classes.menuItem}
                  value={unit.unidade_slug}
                >
                  {unit.unidade_nome}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            classes={{ root: classes.root }}
            error={
              typeof scheduleService === 'string' ||
              scheduleService === undefined ||
              typeof scheduleServiceUser === 'string'
            }
            disabled={!unitySelected}
            variant='outlined'
            className={classes.formControl}
            key={String(selectedDate)}
          >
            <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
              <KeyboardDatePicker
                required
                keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
                disableFuture={false}
                error={
                  (typeof scheduleService === 'string' ||
                    typeof scheduleServiceUser === 'string') &&
                  availableDates.length !== 0
                }
                minDateMessage='Você não pode agendar em dias anteriores'
                invalidDateMessage='A data informada é inválida'
                minDate={new Date()}
                InputProps={{
                  className: classes.menuItem,
                  classes: { adornedEnd: classes.adornedEnd },
                }}
                autoOk
                disabled={
                  !unitySelected ||
                  !availableDates ||
                  availableDates.length === 0
                }
                variant='inline'
                inputVariant='outlined'
                label='Selecione o dia'
                format='dd/MM/yyyy'
                placeholder='00/00/0000'
                PopoverProps={{
                  PaperProps: {
                    style: {
                      backgroundColor: '#fff',
                    },
                  },
                }}
                value={selectedDate || null}
                InputAdornmentProps={{
                  position: 'end',
                  color: '#333',
                }}
                onChange={handleChangeDate}
                shouldDisableDate={date => !isDateAvailable(date)}
                onMonthChange={date =>
                  handleMonthChange(date, unitySelected, serviceData.id)
                }
              />
            </MuiPickersUtilsProvider>
            {typeof scheduleService === 'string' &&
              availableDates.length !== 0 && (
                <FormHelperText>{scheduleService}</FormHelperText>
              )}
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl
            classes={{ root: classes.root }}
            disabled={
              !unitySelected ||
              !selectedDate ||
              typeof scheduleService === 'string' ||
              typeof scheduleServiceUser === 'string' ||
              (scheduleService && Object.keys(scheduleService).length === 0)
            }
            variant='outlined'
            className={classes.formControl}
            error={typeof scheduleServiceUser === 'string'}
            required
          >
            <InputLabel
              className={classes.textLabel}
              id='demo-simple-select-filled-label'
            >
              Escolha o horário
            </InputLabel>
            <Select
              labelId='demo-simple-select-filled-label'
              id='demo-simple-select-filled'
              classes={{
                select: classes.menuItem,
              }}
              onChange={(event): void => {
                setScheduleSelected(event.target.value as string);
              }}
              label='Escolha o horário'
              MenuProps={{
                MenuListProps: {
                  disablePadding: true,
                },
              }}
            >
              {typeof scheduleService !== 'string' &&
                scheduleService?.horarios?.map(schedule => (
                  <MenuItem
                    key={schedule}
                    className={classes.menuItem}
                    value={schedule}
                  >
                    {schedule}
                  </MenuItem>
                ))}
            </Select>
            {typeof scheduleServiceUser === 'string' && (
              <FormHelperText>{scheduleServiceUser}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Box>
  );
}
