import { Box, useMediaQuery } from '@material-ui/core';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { memo, useRef } from 'react';
import { Jutsu } from 'react-jutsu';
import { UserData } from '../../../../interfaces/user';
import getCookie from '../../../../utils/getCookies';

interface Props {
  hideChat: boolean;
  showVideo: boolean;
  roomName: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  main: {
    minWidth: '',
    height: '594px',
    backgroundColor: '#ccc',
    width: '100%',
    marginBottom: '50px',
  },
}));

const Video = ({ hideChat, showVideo, roomName }: Props): JSX.Element => {
  const classes = useStyles();
  const matches = useMediaQuery('(max-width:928px)');
  const dataStorage: string | null = getCookie('gov_user_data');
  const jwtToken = atob(process.env.REACT_APP_JWT_TOKEN) || '';
  const userData: UserData | null = dataStorage
    ? JSON.parse(dataStorage)
    : null;

  const jitsiConfig = {
    roomName,
    password: 'dattebayo',
    subject: 'fan',
    parentNode: 'jitsi-container',
    userInfo: {
      email: userData?.contato?.email || '',
      displayName:
        userData?.nome?.charAt(0).toUpperCase() + userData?.nome?.slice(1) || '',
    },
    interfaceConfigOverwrite: {
      MOBILE_APP_PROMO: false,
      SHOW_CHROME_EXTENSION_BANNER: false,
      SETTINGS_SECTIONS: ['devices', 'language'],
      TOOLBAR_BUTTONS: [
        'microphone',
        'camera',
        'chat',
        'raisehand',
        'tileview',
        'videoquality',
        'fullscreen',
        'settings',
        'shortcuts',
        'hangup',
      ],
    },
    configOverwrite: {
      defaultLanguage: 'pt-br',
      disableDeepLinking: true,
      disableInviteFunctions: true,
      disableProfile: true,
      enableWelcomePage: false,
      disableRemoteMute: true,
      disableModerationNotifications: true,
      remoteVideoMenu: {
        disableKick: true,
        disableMute: true,
        disableGrantModerator: true,
        disablePrivateChat: true,
      },
    },
  };

  const jitsiApiRef = useRef<any>(null);

  const handleApiReady = (JitsiMeetAPI: any) => {
    jitsiApiRef.current = JitsiMeetAPI;

    JitsiMeetAPI.addListener('incomingMessage', handleIncomingMessage);
  };

  const handleIncomingMessage = (data: any) => {
    const { message, senderId } = data;

    if (message === 'REQUEST_RECORDING_PERMISSION') {
      const userResponse = window.confirm(
        'Deseja permitir a gravação desta reunião?',
      );

      const responseMessage = userResponse
        ? 'RECORDING_PERMISSION_GRANTED'
        : 'RECORDING_PERMISSION_DENIED';

      sendBroadcastMessage(responseMessage);
    }
  };

  const sendBroadcastMessage = (message: string) => {
    const jitsiApi = jitsiApiRef.current;
    if (jitsiApi) {
      jitsiApi.executeCommand('sendChatMessage', message);
    }
  };

  return (
    <Box
      className={classes.main}
      minWidth={matches ? '0px' : '650px'}
      marginRight={matches || !hideChat ? '0px' : '10px'}
      display={showVideo ? 'flex' : 'none'}
      hidden={false}
    >
      <Jutsu
        domain={atob(process.env.REACT_APP_MEET_DOMAIN) ?? 'meet.jit.si'}
        containerStyles={{ width: '100%', height: '100%' }}
        roomName={jitsiConfig.roomName}
        password={jitsiConfig.password}
        displayName={jitsiConfig.userInfo.displayName}
        onMeetingEnd={() => console.log('Meeting has ended')}
        loadingComponent={<p>loading ...</p>}
        errorComponent={<p>Oops, something went wrong</p>}
        configOverwrite={jitsiConfig.configOverwrite}
        interfaceConfigOverwrite={jitsiConfig.interfaceConfigOverwrite}
        onApiReady={handleApiReady}
        jwt={jwtToken}
      />
    </Box>
  );
};

export default memo(Video);
