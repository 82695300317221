import makeStyles from '@material-ui/styles/makeStyles';
import theme from '../../../../../../services/theme-service';

export const useStyles = makeStyles({
  content: {
    padding: '24px',
    margin: 0,
  },
  title: {
    margin: 0,
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: theme.typography.pxToRem(16),
    textTransform: 'uppercase',
    marginBottom: 24,
    color: '#333',
  },
  iconOfOptionDefault: {
    '& > SVG': {
      width: 32,
      height: 32,
      marginRight: 16,
    },
  },
  contentOption: {
    display: 'flex',
    width: '100%',
    maxWidth: 600,
    height: 88,
    backgroundColor: 'rgba(38, 90, 170, 0.08)',
    alignItems: 'center',
    padding: 24,
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    borderRadius: 8,
    '&:hover': {
      color: '#fff',
      backgroundColor: theme.palette.primary.main,
    },
    '&:first-of-type': {
      marginBottom: 16,
    },
  },
  contentOptionActive: {
    display: 'flex',
    width: '100%',
    maxWidth: 600,
    height: 88,
    backgroundColor: theme.palette.primary.main,
    alignItems: 'center',
    padding: 24,
    justifyContent: 'space-between',
    color: '#fff',
    cursor: 'pointer',
    transition: 'all 0.2s ease',
    borderRadius: 8,
    '&:first-of-type': {
      marginBottom: 16,
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
  },
  optionText: {
    fontFamily: 'Roboto',
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 500,
  },
  optionDescription: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 400,
    fontFamily: 'Roboto',
    letterSpacing: '0.4px',
    margin: 0,
  },
  arrowIcon: {
    width: 24,
    height: 24,
  },
});
