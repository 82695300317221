import useWebSocket, { ReadyState, Options } from "react-use-websocket";

const wsUrl = atob(process.env.REACT_APP_WEBSOCKET_PAINEL_URL);

export function useWebsocket(options?: Options) {
    const { readyState } = useWebSocket(`${wsUrl}/ws`, options);

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    return { connectionStatus };
}