import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormHelperText from '@material-ui/core/FormHelperText';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import subDays from 'date-fns/subDays';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { ptBR } from 'date-fns/locale';
import { MdEvent } from 'react-icons/md';
import { useScheduleOnline } from '../../../../../../contexts/SchedulerOnlineContext';
import { useDependent } from './useDependent';
import { useStyles } from './styles';

export default function Dependent() {
  const classes = useStyles();
  const {
    nameDependente,
    errorCpfDependente,
    cpfDependente,
    errorNameDependente,
    scheduleService,
    scheduleServiceUser,
    birthDateDependente,
    grauDependenteSelected,
  } = useScheduleOnline();
  const {
    handleChangeCPF,
    handleChangeName,
    handleChangeDate,
    handleChangeGrauDependente,
    optionsDependentes,
  } = useDependent();
  return (
    <Box className={classes.box}>
      <Typography className={classes.titleBox}>dados do dependente</Typography>
      <FormControl
        variant='outlined'
        fullWidth
        error={typeof errorNameDependente === 'string'}
        className={classes.formControl}
        classes={{ root: classes.root }}
        required
      >
        <InputLabel
          className={classes.textLabel}
          htmlFor='outlined-name-dependente'
        >
          Nome Completo
        </InputLabel>
        <OutlinedInput
          id='outlined-name-dependente'
          type='text'
          label='Nome Completo'
          autoFocus
          className={classes.colorInputText}
          value={nameDependente}
          onChange={handleChangeName}
          labelWidth={70}
          inputProps={{ minLength: 6, maxLength: 100 }}
        />
        {typeof errorNameDependente === 'string' && (
          <FormHelperText>{errorNameDependente}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        variant='outlined'
        fullWidth
        className={classes.formControl}
        classes={{ root: classes.root }}
        disabled={!nameDependente}
        error={typeof errorCpfDependente === 'string'}
        required
      >
        <InputLabel
          className={classes.textLabel}
          htmlFor='outlined-name-dependente'
        >
          CPF
        </InputLabel>
        <OutlinedInput
          id='outlined-name-dependente'
          type='text'
          label='cpf'
          autoFocus
          className={classes.colorInputText}
          value={cpfDependente}
          onChange={handleChangeCPF}
          labelWidth={70}
          inputProps={{ maxLength: 14, minLength: 14 }}
        />
        {typeof errorCpfDependente === 'string' && (
          <FormHelperText>{errorCpfDependente}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        classes={{ root: classes.root }}
        error={
          typeof scheduleService === 'string' ||
          typeof scheduleServiceUser === 'string'
        }
        disabled={!cpfDependente}
        variant='outlined'
        className={classes.formControl}
      >
        <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
          <KeyboardDatePicker
            keyboardIcon={<MdEvent style={{ width: 24, height: 24 }} />}
            InputProps={{
              className: classes.menuItem,
              classes: { adornedEnd: classes.adornedEnd },
            }}
            autoOk
            required
            variant='inline'
            inputVariant='outlined'
            label='Data de nascimento'
            minDateMessage='A data não pode ser superior ao mês atual'
            invalidDateMessage='A data informada é inválida'
            format='dd/MM/yyyy'
            maxDate={subDays(new Date(), 30)}
            disabled={!cpfDependente}
            value={birthDateDependente}
            InputAdornmentProps={{
              position: 'end',
              color: '#000',
            }}
            onChange={handleChangeDate}
          />
        </MuiPickersUtilsProvider>
        {typeof scheduleService === 'string' && (
          <FormHelperText>{scheduleService}</FormHelperText>
        )}
      </FormControl>
      <FormControl
        classes={{ root: classes.root }}
        variant='outlined'
        className={classes.formControl}
        disabled={!birthDateDependente || typeof scheduleService === 'string'}
        required
      >
        <InputLabel
          className={classes.textLabel}
          id='demo-simple-select-outlined-label'
        >
          Grau de parentesco
        </InputLabel>
        <Select
          labelId='demo-simple-select-outlined-label'
          id='demo-simple-select-outlined'
          onChange={handleChangeGrauDependente}
          value={grauDependenteSelected || ''}
          label='Grau de parentesco'
          classes={{
            select: classes.menuItem,
          }}
          MenuProps={{
            MenuListProps: {
              disablePadding: true,
            },
          }}
        >
          {optionsDependentes?.map(unity => (
            <MenuItem
              key={unity.id}
              className={classes.menuItem}
              value={unity.option}
            >
              {unity.option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
